/**
 * Interface for the 'Orderform' data
 */
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export interface OrderformPrice {
  net: number;
  net_effective?: number;
  gross: number;
  gross_effective?: number;
  vat?: number;
}

export interface OrderformAmountItem {
  first: OrderformPrice;
  oth: OrderformPrice;
}

export interface OrderformProductItemQuant {
  is_change_allowed: boolean;
  is_editable: boolean;
  min_value: number;
  max_value: number;
  input_type: 'input' | 'none' | 'checkbox' | 'radiobutton';
  max_type: string;
  has_discount: boolean;
  pre_quantity?: number; // Renew
}

export interface OrderformProductItem {
  product_id: number;
  image_url: string;
  headline: string;
  description: string;
  product_description?: string;
  service_description?: string;
  have_variant: boolean;
  variant?: {
    template?: string;
    options: {
      id: number;
      label: {
        label: string;
        disabled: boolean;
      } & string;
    }[];
  };
  have_eticket: boolean;
  eticket?: {
    input_type: 'select' | 'radiobutton';
    template?: string;
    options: {
      id: number;
      label: string;
      stock: string;
      date: string;
      have_stock: 'Y';
      hint: string;
      country: string;
    }[];
  };
  delivery_type?: string;
  is_optional_if_addons_present: string;
  quantity: OrderformProductItemQuant;
  sales_left?: number; // benötigt?
  product_sales_left?: number; // benötigt?
  unit_amounts?: OrderformAmountItem;
  unit_amounts_undiscounted?: OrderformAmountItem;
  total_amounts?: OrderformAmountItem;
  total_amounts_undiscounted?: OrderformAmountItem;
  shipping_amounts?: OrderformAmountItem;
  have_warning: boolean;
  warning_message?: string;
}

export type TypeCountriesList = Array<[string, string] | '-'>;

export interface OrderformProductSettings {
  vat_included_hint: string;
  have_plain_price_view: boolean;
  quantity_input_type: 'input' | 'none' | 'checkbox' | 'radiobutton';
  refund_days: number;
}

export interface OrderformLanguageOptions {
  code: string;
  label: string;
  image_url: string;
  locale: string; // e.g de_DE
}

export interface OrderformCurrencyOption {
  code: string;
  label: string;
}

export interface OrderformGlobalSettings {
  language: string;
  locale: string;
  language_options: OrderformLanguageOptions[];
  currency_code: string;
  currency_symbol: string;
  currency_options: OrderformCurrencyOption[];
  is_show_cent_amount_part_shown: boolean;
  is_search_engine_allowed: boolean;
  is_vat_shown: boolean;
  is_vat_hint_mandatory: boolean;
  is_voucher_input_enabled: boolean;
  is_voucher_input_readonly: boolean;
  payment_legal_hint?: string;
}

export interface OrderformConfig {
  product_id: number;
  orderform_id: number;
  google_api_key: string;
  disable_address_autocomplete: boolean;
  hide_street_number: boolean;
  is_street_number_show_first: boolean;
  can_preselect_payplan: boolean;
  is_pay_button_label_changed_allowed: boolean;
  is_pay_button_label_changed_allowed_countries: string[];
  upsell_session_id?: number;
  siteowner_id: number;
  is_private_siteowner: boolean;
  ajax_key: string;
  buyer_country: string;
  show_order_summary_before_purchase: boolean;
  payment_button_label_overwrite: string | null;
  hide_process_popup?: boolean;
}

export interface OrderformExitPopupSettings {
  enabled: boolean;
  content: string;
  max_count: number;
  current_count: number;
  cookie: string;
  domain: string;
}

export interface OrderformFooterMenu {
  url: string;
  label: string;
  type: string;
  id?: string;
}

export interface OrderformSettingsState {
  config: OrderformConfig;
  global: OrderformGlobalSettings;
  affiliate: {
    name: string;
    note: string;
  };
  product: {
    items: OrderformProductItem[];
    settings: OrderformProductSettings;
  };
  contact_form: {
    refund_waiver_checkboxes: any[];
    fields_billing_address: FormFields.Field[];
    fields_shipping_address: FormFields.Field[];
    postname_prefix: string;
    is_phone_no_mandatory: boolean;
    readonly_buyer_postnames: any[];
    has_title: boolean;
    has_address: boolean;
    has_shipping: boolean;
    has_phone_no_on_address_form_only: boolean;
    has_phone_no_always: boolean;
    is_business_buyer_mandatory: boolean;
    is_business_preselected: boolean;
    is_billing_address_form_open: boolean;
    has_business_option: boolean;
    has_company_input: boolean;
    has_tax_id: boolean;
    addr_salutation_mode: 'if_address_is_shown' | 'always_shown' | 'never_shown';
    default_salutation: 'M' | 'F';
  };
  static_texts: {
    footer_menu: OrderformFooterMenu[];
    legal_notes: string[];
    reseller: string;
  };
  exit_popup: OrderformExitPopupSettings;
  custom_forms: OrderformSettingsStateCustomForms.RootObject[];
  checkboxes: FormFields.Field[];
}

export interface OrderformSelectedPlanQuantityDiscount {
  product_index: number;
  product_id: number;
  html: string;
  label: string | null;
  type: 'discount_not_applied' | 'discount_applied';
}

export interface OrderformSelectedPlan {
  payment_plan_id: string;
  interval_unit: string;
  number_of_installments: number;
  billing_type: string;
  quantity_discounts: OrderformSelectedPlanQuantityDiscount[];
  input_style: string;
  headline_html: string;
  description_html: string;
  rebilling: boolean;
}

export interface OrderformPaymentPlanItem extends OrderformSelectedPlan {
  headline_html: string;
  description_html: string;
  input_style: 'checkbox' | 'radiobutton';
}

export interface OrderformOrderProductItemQuant {
  value: number;
}

export interface OrderformOrderProductItem {
  product_id?: number;
  list_index?: number;
  quantity?: OrderformOrderProductItemQuant;
  eticket_date_id?: number;
  variant_id?: number;
  customForm?: { [formNo: number]: CustomFormData };
}

export interface OrderformOrderOrderState {
  checkboxes: CustomFormData;
  items: { [product_id: number]: OrderformOrderProductItem };
  hidden_inputs?: CustomFormData;
  payment_plan_id: string;
  payment_plan_is_brutto_price_enforced: boolean;
  preselected_payment_plan_id: string;
  selected_plan: OrderformSelectedPlan;
  voucher_code: string;
  currency_code: string;
  currency: string;
  payment_provider: OrderformPaymentProvider;
  custom_forms: CustomFormData;
  discount_display_position: OrderformDiscountDisplayPosition;
  billing_address: CustomFormData;
  shipping_address: CustomFormData;
  splittest_data: string; // to identify spit test product
  has_billing_address: number;
  purchase_id: string;
}

export interface OrderformOrderStateMessageNotificationAction {
  code: string;
  label: string;
  url: string | null;
  ajax_url?: string;
  primary?: true;
  secondary?: true;
}

export interface OrderformOrderStateMessageNotification {
  type: string;
  message: string;
  code: string;
  actions: OrderformOrderStateMessageNotificationAction[];
}

export interface OrderformOrderStateMessages {
  success: any[];
  infos: any[];
  warnings: any[];
  errors: any[];
  notifications: OrderformOrderStateMessageNotification[];
  errors_by_postname: any[];
  plan_info_tooltip: {
    is_shown: boolean;
    label: string;
    tooltip_headline: string;
    tooltip_contents_url: string;
  };
}

export interface OrderformValidationState {
  voucher_code: string;
  form_validation: string;
  errors_by_postname: {
    [key: string]: string;
  };
  success_by_postname: {
    [key: string]: string;
  };
}

export interface OrderformPaymentPlanChangesState {
  new_payment_plan?: string;
  current_payment_plan: string;
}

export interface OrderformOrderState {
  country: string;
  merchant: string;
  settings: OrderformSettingsState;
  payment: OrderformPaymentProvider[];
  payment_plans_headline: string;
  payment_plans: OrderformPaymentPlanItem[];
  order_summary: {
    footnote: string;
    shipping_cost_label: string;
    plan_info_tooltip: {
      is_shown: boolean;
      label: string;
      tooltip_headline: string;
      tooltip_contents_url: string;
    };
    shipping_total: OrderformAmountItem;
    shipping_total_undiscounted: OrderformAmountItem;
    order_total_undiscounted: OrderformAmountItem;
    order_total: OrderformAmountItem;
    brutto_discounts: { reason_html: string; amount: number }[];
    order_final: {
      is_shown: boolean;
      gross_1st: number;
    };
    vat_amounts: {
      vat_rate: number;
      label: string;
      vat_1st: number;
      vat_oth: number;
    }[];
    payment_plan: {
      billing_type: string;
      number_of_installments: number;
      test_interval: string;
      first_billing_interval: string;
      other_billing_intervals: string;
      first_payment_at: string;
    };
  };
  messages: OrderformOrderStateMessages;
  /**
   * all data that are filled from frontend side,
   * no other place in store should be updated from frontend!
   */
  order: OrderformOrderOrderState;
  requestedOrder?: OrderformOrderOrderState;
  execute: {
    action: string;
  };
  validation: OrderformValidationState;
  payment_plan_changes?: OrderformPaymentPlanChangesState;
  express_checkout?: OrderformPaymentProvider;
}

export interface InterfacePaymentProviderStipe {
  apiUrl: string;
  publicKey: string;
  intentClientSecret: string;
}

export interface OrderformPaymentProvider {
  payment_provider_id: string;
  pay_method: string;
  renderer_type: string;
  label_data: {
    default_submit_button: string;
    hint_text: string;
  };
  config_data: any | InterfacePaymentProviderStipe;
  icon_data: {
    text?: string;
    alt: string;
    url: {
      dark: string;
      light: string;
    };
    width: number;
    height: number;
    large?: {
      text?: string;
      url: string;
      width: number;
      height: number;
    };
    small?: {
      text?: string;
      url: string;
      width: number;
      height: number;
    };
  };
  status?: {
    status: 'none' | 'uncertain' | 'completed' | 'refused' | 'authorized' | 'pending' | 'error';
    message: string;
    details?: string;
  };
  disabled: boolean;
  disabled_message: string;
  is_submit_button_visible: boolean;
}

export interface OrderformState {
  order: OrderformOrderState;
  paymentUrl: string;
  pending: boolean;
  submit: 'pending' | 'success' | 'failed' | 'initial';
}

export interface OrderformVoucherValidateResponse {
  status: string;
  status_msg: string;
  discount_rate: number;
  discount_other_rates: number;
  discount_single_rate: number;
  discount_1st_amt: number;
  discount_oth_amts: number;
  discount_single_amt: number;
  code: string;
  is_voucher_code_input_locked: string;
  is_target_first_amount: string;
  target_first_amount: number;
  is_target_other_amounts: string;
  target_other_amounts: number;
  brutto_amount_discount: number;
  show_message: string;
}

export declare namespace FormFields {
  export interface DependsOn {
    // i_order_as: string;
    // country: string;

    [key: string]: string;
  }

  export interface SelectOption {
    [key: string]: string;
  }

  export interface Field {
    // fields identical with address and custom fields
    field_type?: string;
    type: string;
    postname: string;
    label: string;
    fieldname?: string;
    message_html?: string; // Custom Input (text)
    address_field?: any;
    is_used_in_eticket?: boolean;
    in_row_with_next?: boolean;
    max_value?: string | number;
    min_value?: number;
    maxlength?: string | number;
    minlength?: string | number;
    size?: number;
    rules?: string;
    id?: string;
    value?: string;
    input_position?: string;
    with_time?: boolean;
    skip_blacklist_check?: boolean;
    regex?: string;
    regex_message?: string;
    rows?: string;
    options?: false | Array<SelectOption | '-'>;
    past_dates_only?: boolean;
    future_date_only?: boolean;
    is_required?: boolean;
    is_checked?: boolean;
    is_readonly?: boolean;
    hint?: string;
    multi_select?: boolean;
    seperator?: string;

    // address interface comes only from address
    tooltip?: string;
    button?: string;
    theme?: string;
    default?: string;
    inline_label?: string;
    depends_on?: DependsOn;
    hidden_html?: string;
    autocomplete?: string;
    with_validation_icon?: boolean;
    hide?: boolean;
    placeholder?: string;
    css?: string;
    allow_null?: boolean;
    null_label?: string;
    onchange?: string;
    country_restriction_hint?: string;
    validators?: ValidatorFn[];
    asyncValidators?: AsyncValidatorFn[];
    'grid-col-width'?: number;
    is_latin_only?: boolean;
  }
}

export declare namespace OrderformSettingsStateCustomForms {
  export interface RootObject {
    no: number;
    headline: string;
    description: string;
    fields: FormFields.Field[];
    show_on: 'form' | 'product';
    show_on_products: number[];
  }
}

export interface CustomFormData {
  [postKeyFromBackend: string]: any; // CF-3533-6241-1-22: 'Hallo'
}

// POST Interfaces
export interface InterfacePost {
  splittest_data?: string;
  checkboxes?: CustomFormData;
  hidden_inputs?: CustomFormData;
  shipping_address?: CustomFormData;
  billing_address?: CustomFormData;
  has_billing_address?: number;
  //buyer?: CustomFormData;
  items?: {
    [product_id: number]: OrderformOrderProductItem;
  };
  payment_plan_id?: string;
  payment_plan_is_brutto_price_enforced?: 'Y' | 'N' | any;
  voucher_code?: string;
  voucher_code_added?: boolean;
  custom_forms?: CustomFormData;
  payment_provider?: OrderformPaymentProvider;
  payment_provider_meta?: CustomFormData;
  currency?: string;
  discount_display_position?: OrderformDiscountDisplayPosition;
  iframe_parent_url?: string;
  preselected_payment_plan_id?: string;
  selected_plan?: OrderformSelectedPlan;
  currency_code?: string;
  purchase_id?: string;
}

export type OrderformDiscountDisplayPosition = 'summary' | 'item' | 'summary,item';

export interface OrderformRedirectResponse {
  action: 'redirect' | 'show_message';
  headline: string;
  message: string;
  url: string;
  redirect_id?: string;
}

export interface CheckDoubleOrderResponse extends OrderformOrderStateMessageNotification {
  type: 'hidden' | 'lightbox';
  debug_info: string;
}

export type OrderformMessageType = 'success' | 'infos' | 'warnings' | 'errors';

export interface OrderFormValidationResult {
  valid: boolean;
  component: string;
  invalidFieldList: {
    id: string;
    errors: string[];
  }[];
}

export interface EnhancedDeclinedPaymentMessage {
  message: string;
}

export enum SiteOwners {
  DIGISTORE24_GMBH = 1,
  DIGISTORE24_INC = 2,
  DIGISTORE24_LTD = 3,
  DIGISTORE24_MSLW_LIMITED = 4,
}
